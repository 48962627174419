import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  fetchUserOrganisationMemberships,
  setSelectedUserOrganisationMembership,
} from '@app-state/user/user-organisation-membership/user-organisation-membership.action';
import {
  allUserOrgMemberships,
  selectedUserOrgMembership,
} from '@app-state/user/user-organisation-membership/user-organisation-membership.selectors';
import { IsPlatform } from '@class/commons/is-platform';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { PoweredByFooterComponent } from '@custom-component/powered-by-footer/powered-by-footer.component';
import { UserSettingComponent } from '@custom-component/user-setting/user-setting.component';
import { IfHasPermissionDirective } from '@custom-directive/permissions/if-has-permission.directive';
import { IonicModule, MenuController, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AnalyticsService } from '@service/common/analytics.service';
import { CommonService } from '@service/common/common.service';
import { ProfileService } from '@service/profile/profile.service';
import { ReseterService } from '@service/reseter/reseter.service';
import { ThemeService } from '@service/themes/theme.service';
import { AppAssets, SvgIconNames } from '@theme/theme-map';
import { environment } from 'environments/environment';
import { map, tap } from 'rxjs';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    IonicModule,
    CommonModule,
    PoweredByFooterComponent,
    RouterModule,
    IfHasPermissionDirective,
  ],
})
export class SideMenuComponent {
  readonly privacyPolicy = 'https://switchdin.com/privacy-policy';
  readonly termsConditions = 'https://switchdin.com/terms-and-conditions';

  readonly homeIcon = `${AppAssets.ICONS_SVG_MENU}${SvgIconNames.HOME}`;
  readonly portfolioIcon = `${AppAssets.ICONS_SVG_MENU}${SvgIconNames.LOCATION_CITY}`;
  readonly vppIcon = `${AppAssets.ICONS_SVG_MENU}${SvgIconNames.GRAPHIC_EQ}`;
  readonly installerIcon = `${AppAssets.ICONS_SVG_MENU}${SvgIconNames.CAST}`;
  readonly orgSettingIcon = `${AppAssets.ICONS_SVG_MENU}${SvgIconNames.SETTINGS}`;
  readonly supportIcon = `${AppAssets.ICONS_SVG_MENU}${SvgIconNames.HELP_CIRCLE}`;
  readonly userIcon = `${AppAssets.ICONS_SVG_MENU}${SvgIconNames.USER}`;
  readonly logoutIcon = `${AppAssets.ICONS_SVG_MENU}${SvgIconNames.LOGOUT}`;

  readonly isPlatformCordova = IsPlatform.CORDOVA;
  readonly PermissionKey = PermissionKey;

  readonly version = environment.version;

  readonly headerLogoPath$ = this.theme.userTheme$.pipe(map((theme) => theme.headerLogo.path));
  readonly headerLogoAltName$ = this.theme.userTheme$.pipe(map((theme) => theme.name));
  readonly userFriendlyName$ = this.profileService.profile$.pipe(
    map((profile) => `${profile.first_name} ${profile.last_name}`),
  );
  readonly isLoggedIn$ = this.common.loggedInSubject.asObservable().pipe(
    map((loggedIn) => !!loggedIn),
    tap((loggedIn) => this.menu.enable(loggedIn)),
  );

  readonly #store = inject(Store);
  readonly userOrganisation$ = this.#store.select(allUserOrgMemberships);
  readonly selectedUserOrganisation$ = this.#store.select(selectedUserOrgMembership);

  constructor(
    private theme: ThemeService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private reseterService: ReseterService,
    private menu: MenuController,
    private profileService: ProfileService,
    private common: CommonService,
    private modalCtrl: ModalController,
  ) {
    this.#store.dispatch(fetchUserOrganisationMemberships());
  }

  isOrgSettingFeatureFlagEnabled(): boolean {
    return this.analyticsService?.plugins?.posthog?.isFeatureEnabled('org-settings');
  }

  async logout() {
    try {
      await this.reseterService.run();
      this.router.navigate(['account/login']);
    } catch (error) {
      console.error(error);
    }
  }

  async presentProfileModal(): Promise<void> {
    this.menu?.close();
    const modal = await this.modalCtrl.create({
      component: UserSettingComponent,
      cssClass: 'large-modal',
      backdropDismiss: false,
    });
    await modal.present();
  }
  handleUnitFilterChange(event: CustomEvent): void {
    const value = event.detail.value;
    this.#store.dispatch(setSelectedUserOrganisationMembership({ orgUuid: value }));
    this.menu?.close();
    if (this.router.url.includes('organisation-settings')) {
      this.router.navigate(['organisation-settings', value, 'unit-list'], { replaceUrl: true });
    }
  }
}
