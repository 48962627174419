import { UnitsService } from './../units/units.service';
import { Injectable, inject } from '@angular/core';
import { Portfolio } from '../../classes/commons/portfolio.model';
import { ApiWrapper } from '../common/api-wrapper.service';
import { AvailableAPI, RequestMethod, UseHeaderType } from '../../classes/commons/request-api.model';
import { FavKeys, FavouritesService } from '../common/favourites.service';
import { PermissionsService } from '@service/permissions/permissions.service';
import { PermissionArea, PermissionKey } from '@class/commons/permissions/permission-constants';
import { Router } from '@angular/router';
import { ChartdataApiService } from 'app/api-services/chartdata.api-service';
import { filterSuccessResult } from '@ngneat/query';
import { TimePeriodResolution } from '@class/commons/constants-datetime';
import { first, firstValueFrom, map } from 'rxjs';

export interface GetPortfolioChartdataParams {
  portfolioUuid: string;
  metric: string;
  timePeriod?: TimePeriodResolution;
  start: number;
  end: number;
}

// TODO: This definitely requires attention
// no-type data, poorly managed state

export type PortfolioPublish = { portfolio: Portfolio; apiCall: boolean; dataExist: boolean; apiCallFailed: boolean };

@Injectable({
  providedIn: 'root',
})
export class PortfoliosService {
  private _chartdataApiService = inject(ChartdataApiService);

  constructor(
    private api: ApiWrapper,
    private permissionsService: PermissionsService,
    private unitsService: UnitsService,
    private favouritesService: FavouritesService,
    private router: Router,
  ) {}

  navigateToPortfolios(): void {
    this.router.navigateByUrl(`/portfolios`);
  }

  resetService() {
    this.resetSelectedPortfolio();
    this.permissionsService.clear(PermissionArea.PORTFOLIO);
  }

  resetSelectedPortfolio(): void {
    this.permissionsService.clear(PermissionArea.PORTFOLIO);
  }

  getChartData({
    portfolioUuid,
    metric,
    timePeriod = TimePeriodResolution.HOUR,
    start,
    end,
  }: GetPortfolioChartdataParams) {
    const result = this._chartdataApiService
      .getChartdata({
        field: 'portfolio_uuid',
        uuid: portfolioUuid,
        metrics: [metric],
        period: timePeriod,
        from: start,
        to: end,
        extraColumns: ['unit_uuid'],
      })
      .result$.pipe(
        filterSuccessResult(),
        first(),
        map((result) => result.data),
      );

    return firstValueFrom(result);
  }

  inviteUser(portfolioId: string, data) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/portfolios/' + portfolioId + '/invite_user/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  revokeUser(membershipId: string) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/portfolio-memberships/' + membershipId + '/',
      RequestMethod.DELETE,
      UseHeaderType.AUTHORIZED_SWDIN,
    );
  }

  // only getting used from portfolio settings
  // need to improve this
  async deleteSelectedPortfolio(portfolio: Portfolio) {
    if (!this.permissionsService.any([PermissionKey.PORTFOLIO_DELETE_PORTFOLIO])) {
      return Promise.reject('No permission to delete portfolios.');
    }
    const selectedPortfolioID = portfolio.id;
    if (!selectedPortfolioID) {
      const errorMessage = `Warning, attempting to delete a portfolio that has not been selected, id: ${selectedPortfolioID}`;

      return Promise.reject(errorMessage);
    }

    try {
      if (portfolio.unitSet.length > 0) {
        const deletePromises = portfolio.unitSet.map((unit: { id: string }) => {
          return this.unitsService.deleteUnit(unit.id);
        });
        await Promise.allSettled(deletePromises);
      }

      await this.favouritesService.remove(portfolio, FavKeys.PORTFOLIOS);

      return this.api.handleRequest(
        AvailableAPI.SWITCHDIN,
        `/api/v1/portfolios/${selectedPortfolioID}/`,
        RequestMethod.DELETE,
        UseHeaderType.AUTHORIZED_SWDIN,
        {},
      );
    } catch (err) {
      console.log(err);
      return Promise<unknown>;
    }
  }
}
