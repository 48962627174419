import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from './guards/logged-in.guard';
import { AuthGuard } from './guards/auth.guard';

const ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'account',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('./pages/accounts/account/account.module').then((m) => m.AccountPageModule),
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then((m) => m.LogoutPageModule),
  },
  {
    path: 'unit/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/unit-view/unit-view.module').then((m) => m.UnitViewPageModule),
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'portfolios',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/portfolios/portfolios.module').then((m) => m.PortfoliosPageModule),
  },
  {
    path: 'portfolio/:id',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/portfolios/portfolio-view/portfolio-view.module').then((m) => m.PortfolioViewPageModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/error-page/error-page.module').then((m) => m.ErrorPageModule),
  },
  {
    path: 'virtual_power_plants',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/virtual-power-plants/virtual-power-plants.module').then((m) => m.VirtualPowerPlantsPageModule),
  },
  {
    path: 'configure',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/installer/installer.module').then((m) => m.InstallerPageModule),
  },
  {
    path: 'organisation-settings/:uuid',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/organisation-settings/organisation-settings.module').then(
        (m) => m.OrganisationSettingsPageModule,
      ),
  },
  // NOTE: this wildcard needs to go last
  {
    path: '**',
    redirectTo: '/error/page-not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
