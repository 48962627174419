import { Injectable, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { States } from '@class/commons/constants';
import { PermissionArea } from '@class/commons/permissions/permission-constants';
import { Portfolio, PortfolioDTO } from '@class/commons/portfolio.model';
import { Unit } from '@class/commons/unit.model';
import { generateHttpErrorMessage, HTTP_ERRORS_TRANSLATION_HEADING } from '@class/error/http-error-types';
import { AppHeaderService } from '@service/common/app-header.service';
import { FavKeys, FavouritesService } from '@service/common/favourites.service';
import { TranslationsService } from '@service/common/translations.service';
import { PermissionsService } from '@service/permissions/permissions.service';
import { BehaviorSubject, Observable, map, catchError, startWith, tap, of, from } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { CollectionApiService } from 'app/api-services/collection/collection.api-service';
import { filterSuccessResult } from '@ngneat/query';

type UuidKeyName = { [index: string]: string };
export type PortfolioOverviewChartInputData = {
  uuidToGetData: string;
  unitUuidKeyNameValues: UuidKeyName;
};

interface PortfolioStateData {
  data: Portfolio;
  overviewChartData?: PortfolioOverviewChartInputData;
  status: States;
  error?: string;
  message?: string;
}
const PORTFOLIO_INITIAL_STATE: PortfolioStateData = {
  status: States.INITIAL_LOADING,
  data: null,
};

export interface PortfolioForm {
  name: FormControl<string>;
  location_description: FormControl<string>;
  mute_alerts: FormControl<boolean>;
}
interface PortfolioFormState {
  data: FormGroup<PortfolioForm>;
  status: States;
  error?: string;
  message?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PortfolioStore {
  #collectionApiService = inject(CollectionApiService);

  private _portfolioStore = new BehaviorSubject<PortfolioStateData>(PORTFOLIO_INITIAL_STATE);
  portfolio$ = this._portfolioStore.asObservable();

  private _portfolioForm = this._formBuilder.group({
    name: ['', Validators.required],
    location_description: [''],
    mute_alerts: [false],
  });
  readonly PORTFOLIO_FORM_INITIAL_STATE: PortfolioFormState = {
    data: this._portfolioForm,
    status: States.INITIAL_LOADING,
  };
  private _portfolioSettingsFormStore = new BehaviorSubject<PortfolioFormState>(this.PORTFOLIO_FORM_INITIAL_STATE);
  portfolioSettingsForm$ = this._portfolioSettingsFormStore.asObservable();

  constructor(
    private _translationsService: TranslationsService,
    private _headerService: AppHeaderService,
    private _permissionsService: PermissionsService,
    private _formBuilder: FormBuilder,
    private _favouritesService: FavouritesService,
    // private _portfolioListStore: PortfolioListStore,
  ) {}

  getPortfolioById(portfolio_id: string): void {
    this.#collectionApiService
      .collectionDetail(portfolio_id)
      .result$.pipe(
        filterSuccessResult(),
        map(({ data }) => {
          const portfolioData = PortfolioDTO.adaptToPortfolio(data as unknown as PortfolioDTO);

          const unitUuidKeyNameValues: UuidKeyName = {};

          portfolioData.unitSet.forEach((unit) => {
            unitUuidKeyNameValues[unit.uuid] = unit.name;
          });
          const overviewChartData = {
            uuidToGetData: portfolioData.uuid,
            unitUuidKeyNameValues: unitUuidKeyNameValues,
          };
          return {
            data: portfolioData,
            overviewChartData: overviewChartData,
            status: States.INITIAL_DATA_RECEIVED,
          };
        }),
        catchError((error: HttpErrorResponse) => this.handleError(error)),
        startWith(PORTFOLIO_INITIAL_STATE),
        tap((portfolioData) => {
          // this is hack
          if (portfolioData.data) {
            this._permissionsService.apply(PermissionArea.PORTFOLIO, portfolioData.data.permissions);
            this._headerService.setHeaderTitle(portfolioData.data.name);

            // get portfolio setting form and publish it
            this._portfolioSettingsFormStore.next({
              data: this.setAndUpdatePortfolioSettingForm(portfolioData.data),
              status: portfolioData.status,
            });
            // add the portfolio in fav
            this._favouritesService.add(portfolioData.data, FavKeys.PORTFOLIOS);
          }

          this._portfolioStore.next(portfolioData);
        }),
      )
      .subscribe();
  }

  updatePortfolio(
    data: Partial<{
      name: string;
      location_description: string;
      mute_alerts: boolean;
    }>,
  ): void {
    const portfolioId = this._portfolioStore.value.data.id;
    this.updateSelectedPortfolioData(portfolioId, data).subscribe();
  }

  // hack
  // to get portfolio for delete
  getPortfolio(): Portfolio {
    return { ...this._portfolioStore.value.data };
  }

  updateUnitInPortfolio(updatedUnit: Unit): void {
    // update the unit in portfolio list if there is any data

    if (this._portfolioStore.value.data) {
      const updatedUnitSet = this._portfolioStore.value.data.unitSet.map((unit) => {
        if (unit.uuid === updatedUnit.uuid) {
          return updatedUnit;
        }
        return unit;
      });
      this._portfolioStore.next({
        data: {
          ...this._portfolioStore.value.data,
          unitSet: [...updatedUnitSet],
          unitCount: updatedUnitSet.length,
        },
        status: States.INITIAL_DATA_RECEIVED,
      });
    }
  }
  removeUnitInPortfolio(unitUuid: string) {
    // remove the unit in portfolio list if there is any data

    if (this._portfolioStore.value.data) {
      const updatedUnitSet = this._portfolioStore.value.data.unitSet.filter((unit) => unit.uuid !== unitUuid);
      this._portfolioStore.next({
        data: {
          ...this._portfolioStore.value.data,
          unitSet: [...updatedUnitSet],
          unitCount: updatedUnitSet.length,
        },
        status: States.INITIAL_DATA_RECEIVED,
      });
    }
  }

  updateHeaderTitle(): void {
    this._headerService.setHeaderTitle(this._portfolioStore.value.data?.name);
  }

  // Private Methods
  private setAndUpdatePortfolioSettingForm(portfolio: Portfolio): FormGroup<PortfolioForm> {
    return this._formBuilder.group({
      name: [portfolio.name, Validators.required],
      location_description: [portfolio.locationDescription],
      mute_alerts: [{ value: portfolio.muteAlerts, disabled: portfolio.muteAlerts == null }],
    });
  }

  private updateSelectedPortfolioData(
    portfolioId: string,
    data: Partial<{
      name: string;
      location_description: string;
      mute_alerts: boolean;
    }>,
  ) {
    return from(this.#collectionApiService.updateCollection(portfolioId).mutateAsync(data)).pipe(
      map((portfolio): PortfolioStateData => {
        const portfolioData = PortfolioDTO.adaptToPortfolio(portfolio as unknown as PortfolioDTO);
        return {
          status: States.INITIAL_DATA_RECEIVED,
          data: portfolioData,
          message: this._translationsService.instant('CreatePortfolioModal.PortfolioUpdatedSuccess'),
        };
      }),
      // catchError((error) => this.handleError(error)),
      startWith({ status: States.REQUEST_IN_PROCESS, data: null } as PortfolioStateData),

      tap((portfolioData: PortfolioStateData) => {
        // this is hack
        if (portfolioData.data) {
          this._headerService.setHeaderTitle(portfolioData.data.name);

          this._portfolioSettingsFormStore.next({
            data: this.setAndUpdatePortfolioSettingForm(portfolioData.data),
            status: portfolioData.status,
          });

          // update the current portfolio store
          this._portfolioStore.next({ ...portfolioData, status: States.INITIAL_DATA_RECEIVED });

          // update the fav  list
          this._favouritesService.add(portfolioData.data, FavKeys.PORTFOLIOS);

          // ToDo
          // update the data of updated portfolio in portfolio list store
          // this._portfolioListStore.updateDataOnPortfolioIndex(portfolioData.data);
        } else {
          this._portfolioSettingsFormStore.next({ status: portfolioData.status, data: null });
        }
      }),
    );
  }

  private handleError(err: HttpErrorResponse, state: States = States.INITIAL_ERROR): Observable<PortfolioStateData> {
    const message = generateHttpErrorMessage(err, this._translationsService.instant(HTTP_ERRORS_TRANSLATION_HEADING));
    return of({
      status: state,
      error: message.message,
      data: null,
    });
  }
}
