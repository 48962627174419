import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { isEmpty } from 'lodash';
import { ProfileService, UserProfile } from '../../services/profile/profile.service';
import { THEMES } from '../../../theme/theme-map';
import { ModalController } from '@ionic/angular';
import { firstValueFrom, Subscription } from 'rxjs';
import { LanguagePayload, TranslationsService } from '../../services/common/translations.service';
import { FacadeStates } from '../../classes/commons/facade-states';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericAlertsToastsService } from '@service/common/generic-alerts-toasts.service';
import { AlertButtonRoles } from '@class/alerts-toasts-props/alert-props';
import { ReseterService } from '@service/reseter/reseter.service';
import { Router } from '@angular/router';

interface ProfileForm {
  firstName: string;
  lastName: string;
  address: string;
  phone: string;
  language: number;
  isDarkTheme: boolean;
  isMuteAlerts: boolean;
  theme: string;
  apiToken: string;
}
@Component({
  selector: 'app-profile-component',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnDestroy {
  form: UntypedFormGroup;
  status = FacadeStates.LOADING;
  error: string;
  languages$ = this.translationService.getAvailableLanguages();

  readonly THEMES = THEMES;
  readonly FacadeStates = FacadeStates;

  private subscription: Subscription;

  constructor(
    private profileService: ProfileService,
    private modalController: ModalController,
    private translationService: TranslationsService,
    private genericAlertsToastsService: GenericAlertsToastsService,
    private reseterService: ReseterService,
    private router: Router,
  ) {
    this.subscription = this.profileService.profile$.subscribe((userProfile) => {
      this.status = FacadeStates.LOADING;
      try {
        this.form = this.initialiseForm(userProfile);
        this.status = FacadeStates.ONGOING;
      } catch (err) {
        this.status = FacadeStates.ERROR;
        const errorDetail = err instanceof HttpErrorResponse ? `${err.name} ${err.status}: ${err.statusText}` : '';
        this.error = `${this.translationService.instant('ProfilePage.UnableToFetchProfile')}. ${errorDetail}`;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.subscription = null;
  }

  private initialiseForm(userProfile: UserProfile): UntypedFormGroup {
    if (isEmpty(userProfile)) return null;
    const { first_name, last_name, profile } = userProfile;
    const { address, contact_phone, language, dark_theme, mute_alerts, browser_skin, api_token } = profile;
    const form = new UntypedFormGroup({
      firstName: new UntypedFormControl(first_name),
      lastName: new UntypedFormControl(last_name),
      address: new UntypedFormControl(address),
      phone: new UntypedFormControl(contact_phone),
      language: new UntypedFormControl(language ?? null),
      isDarkTheme: new UntypedFormControl(dark_theme, Validators.required),
      isMuteAlerts: new UntypedFormControl(mute_alerts, Validators.required),
      theme: new UntypedFormControl(browser_skin, Validators.required),
      apiToken: new UntypedFormControl(api_token),
    });
    return form;
  }

  close(): void {
    this.modalController.dismiss();
  }

  compareLanguageObj(a: LanguagePayload, b: LanguagePayload) {
    return a.id === b.id;
  }

  async submit(form: ProfileForm): Promise<void> {
    this.status = FacadeStates.LOADING;
    try {
      const currentProfile = await firstValueFrom(this.profileService.profile$);
      const { address, phone, language, isDarkTheme, isMuteAlerts, theme } = form;
      const profile = {
        ...currentProfile.profile,
        browser_skin: theme,
        address,
        contact_phone: phone,
        language,
        dark_theme: isDarkTheme,
        mute_alerts: isMuteAlerts,
      };
      const payload: UserProfile = { ...currentProfile, profile };

      await this.profileService.updateLoggedInProfile(payload);
      this.translationService.setUserLanguage(language);
      this.status = FacadeStates.COMPLETE;
      setTimeout(() => {
        this.modalController.dismiss();
      }, 1000);
    } catch (err) {
      this.status = FacadeStates.ERROR;
      const errorDetail = err instanceof HttpErrorResponse ? `${err.name} ${err.status}: ${err.statusText}` : '';
      this.error = `${this.translationService.instant('ProfilePage.UnableToUpdateProfile')}. ${errorDetail}`;
    }
  }

  async presentAccountDeleteConfirmationAlert(): Promise<void> {
    const { AccountDeleteHeader, AccountDeleteSubheader, AccountDeleteMsg } =
      this.translationService.instant('ProfilePage');
    const alert = await this.genericAlertsToastsService.createAlertWithCancelRemoveButton(
      AccountDeleteHeader,
      AccountDeleteSubheader,
      AccountDeleteMsg,
    );
    await alert.present();

    const { role } = await alert.onDidDismiss();
    if (role === AlertButtonRoles.REMOVE) {
      this.deleteUserAccount();
    }
  }

  private async deleteUserAccount(): Promise<void> {
    this.status = FacadeStates.LOADING;
    try {
      await this.profileService.deleteUserAccount();
      // clear all the data & remove the tokens
      await this.reseterService.run();
      await this.modalController.dismiss();
      this.router.navigateByUrl('/account/login');

      const { AccountDeleteSuccessHeader, AccountDeleteSuccessMsg } = this.translationService.instant('ProfilePage');

      const alert = await this.genericAlertsToastsService.createAlertWithOkButton(
        AccountDeleteSuccessHeader,
        '',
        AccountDeleteSuccessMsg,
      );
      alert.present();
    } catch (error) {
      this.status = FacadeStates.ERROR;
      const errorDetail =
        error instanceof HttpErrorResponse ? `${error.name} ${error.status}: ${error.statusText}` : '';
      this.error = `${this.translationService.instant('ProfilePage.UnableToDeleteProfile')}. ${errorDetail}`;
    }
  }
}
