import { Injectable, inject } from '@angular/core';
import { States } from '@class/commons/constants';
import { PermissionArea, PermissionKey } from '@class/commons/permissions/permission-constants';
import { ToolbarInterface } from '@class/commons/toolbar-interface';
import { Unit, UnitDTO } from '@class/commons/unit.model';
import { HTTP_ERRORS_TRANSLATION_HEADING, generateHttpErrorMessage } from '@class/error/http-error-types';
import { AppHeaderService } from '@service/common/app-header.service';
import { FavKeys, FavouritesService } from '@service/common/favourites.service';
import { TranslationsService } from '@service/common/translations.service';
import { PermissionsService } from '@service/permissions/permissions.service';
import {
  Observable,
  catchError,
  distinctUntilChanged,
  filter,
  from,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { UnitMqttStore } from './unit-mqtt.store';
import { ComponentStore } from '@ngrx/component-store';
import { UnitsApiService } from 'app/api-services/units/units.api-service';
import { HttpErrorResponse } from '@angular/common/http';
import { filterSuccessResult } from '@ngneat/query';
import { PortfolioStore } from '@service/portfolios/portfolio.store';
import { Store } from '@ngrx/store';
import { setAppToastNotification } from '@app-state/app-toast-notifications/app-toast-notifications.actions';
import { NavController } from '@ionic/angular';
import { UnitOperationalStates } from '@class/commons/enums';
import { showActionPendingState } from '@utils/unit/unit-operational-state';

interface UnitStateData {
  data: Unit;
  tabs?: ToolbarInterface[];
  status: States;
  error?: string;
  message?: string;
}

const UNIT_INITIAL_STATE: UnitStateData = {
  status: States.INITIAL_LOADING,
  data: null,
};

const TABS: ToolbarInterface[] = [
  {
    name: 'overview',
    label: 'UnitPage.OverviewTabText',
    icon: 'stats-chart-outline',
    permissions: true,
  },
  {
    name: 'equipments',
    label: 'UnitPage.EquipmentTabText',
    icon: 'list-outline',
    permissions: [PermissionKey.UNIT_VIEW_DEVICE, PermissionKey.UNIT_VIEW_CONTROLLER],
  },
  {
    name: 'alerts',
    label: 'Alerts.Alerts',
    icon: 'notifications-outline',
    permissions: [PermissionKey.UNIT_VIEW_ALERT, PermissionKey.UNIT_VIEW_ALERTDEFINITION],
  },
  {
    name: 'extras',
    label: 'UnitPage.ExtraTabText',
    icon: 'cash-outline',
    permissions: [PermissionKey.UNIT_VIEW_EVENT],
  },
  {
    name: 'settings',
    label: 'UnitPage.SettingsTabText',
    icon: 'build-outline',
    permissions: [PermissionKey.UNIT_CHANGE_UNIT, PermissionKey.UNIT_VIEW_UNITMEMBERSHIP],
  },
];

@Injectable({
  providedIn: 'root',
})
export class UnitStore extends ComponentStore<UnitStateData> {
  #portfolioStore = inject(PortfolioStore);
  #store = inject(Store);
  #navController = inject(NavController);

  #mutateUnitData = this._unitsApiService.patchUnit();
  #purgeUnit = this._unitsApiService.purgeUnit();

  /** ** ** SELECTORS ** ** ** */
  readonly purgeUnitData$ = this.select((state) => state.data).pipe(
    filter((data) => data !== null),
    map((unit) => ({ unitId: unit.id, unitUuid: unit.uuid, portfolio: unit.portfolio })),
  );
  readonly unit$ = this.select((state) => state);
  readonly isUnitUpdating$ = this.#mutateUnitData.result$.pipe(
    distinctUntilChanged(),
    map((res) => res.isPending),
  );
  readonly unitOperationalState$ = this.select((state) => state.data?.operationalState).pipe(
    filter((uos) => uos !== undefined || uos !== null),
    distinctUntilChanged(),
  );
  readonly isUnitActive$ = this.select((state) => state.data?.operationalState).pipe(
    filter((uos) => uos !== undefined || uos !== null),
    distinctUntilChanged(),
    map((uos) => uos === UnitOperationalStates.ACTIVE || uos === UnitOperationalStates.NOT_CONFIGURED),
  );
  readonly showActionPendingState$ = this.select((state) => state.data).pipe(
    filter((unit) => unit !== undefined && unit !== null),
    map((unit) => showActionPendingState(unit.actionPending, unit.operationalState)),
  );
  readonly isUnitDeleted$ = this.select((state) => state.data?.operationalState).pipe(
    filter((uos) => uos !== undefined || uos !== null),
    distinctUntilChanged(),
    map((uos) => uos === UnitOperationalStates.DELETED || uos === UnitOperationalStates.PURGED),
  );

  #unitOperationalStateAndUuid = this.select((state) => state.data).pipe(
    filter((data) => data !== null),
    map((unit) => ({ unitOperationalState: unit.operationalState, uuid: unit.uuid })),
  );

  /** ** ** EFFECTS ** ** ** */
  readonly updateUnit = this.effect(
    (updatedUnitData$: Observable<{ unitData: Partial<UnitDTO>; unitUuid: string }>) => {
      return updatedUnitData$.pipe(
        switchMap(({ unitData, unitUuid }) => this.#mutateUnitData.mutateAsync({ uuid: unitUuid, data: unitData })),
        map((res) => new Unit(res)),
        tap((unit) => this.setUnitData(unit)),
        tap((unit) => this._headerService.setHeaderTitle(unit.name)),
        tap((unit) => this._favouritesService.add(unit, FavKeys.UNITS)),
        tap((unit) => this.#portfolioStore.updateUnitInPortfolio(unit)),
        catchError((error) => this.handleError(error)),
        tap((unitData: UnitStateData) => {
          if (unitData.error) {
            this.#store.dispatch(
              setAppToastNotification({
                toastSettings: {
                  toastType: 'error',
                  message: unitData.error,
                  header: '',
                },
              }),
            );
          }
        }),
      );
    },
  );
  readonly purgeUnit = this.effect(
    (
      purgeUnitData$: Observable<{
        unitId: string;
        unitUuid: string;
        portfolio: { id: string; name: string; uuid: string };
      }>,
    ) => {
      return purgeUnitData$.pipe(
        tap((unit) => this._favouritesService.remove({ id: unit.unitId }, FavKeys.UNITS)),
        tap((unit) => this.#portfolioStore.removeUnitInPortfolio(unit.unitUuid)),
        switchMap((unit) => from(this.#purgeUnit.mutateAsync({ uuid: unit.unitUuid })).pipe(map(() => unit))),
        tap((unit) => {
          this.#navController.navigateBack(`portfolio/${unit.portfolio.uuid}`);
        }),
        catchError((error) => this.handleError(error)),
        tap((unitData: UnitStateData) => {
          if (unitData?.error) {
            this.#store.dispatch(
              setAppToastNotification({
                toastSettings: {
                  toastType: 'error',
                  message: unitData.error,
                  header: '',
                },
              }),
            );
          }
        }),
      );
    },
  );

  constructor(
    private _permissionsService: PermissionsService,
    private _headerService: AppHeaderService,
    private _favouritesService: FavouritesService,
    private _translationsService: TranslationsService,
    private _unitMqttStore: UnitMqttStore,
    private _unitsApiService: UnitsApiService,
  ) {
    super(UNIT_INITIAL_STATE);

    this.#unitOperationalStateAndUuid.pipe(distinctUntilChanged()).subscribe(({ unitOperationalState, uuid }) => {
      if (
        unitOperationalState === UnitOperationalStates.ACTIVE ||
        unitOperationalState === UnitOperationalStates.NOT_CONFIGURED
      ) {
        this._unitMqttStore.establishMqttForUnit(uuid);
      } else {
        this.destroyUnitMqttStream();
      }
    });
  }

  getUnitById(unitUuid: string): void {
    this._unitsApiService
      .getUnit({ unitUuid })
      .result$.pipe(
        filterSuccessResult(),
        map((res) => res.data),
        shareReplay(),
        map((unit) => {
          const unitData = new Unit(unit);

          return {
            data: unitData,
            status: States.INITIAL_DATA_RECEIVED,
          };
        }),
        catchError((error) => this.handleError(error)),
        startWith(UNIT_INITIAL_STATE),
        tap((unitData) => {
          // this is hack
          if (unitData.data) {
            this._permissionsService.apply(PermissionArea.UNIT, unitData.data.permissions);
            this._headerService.setHeaderTitle(unitData.data.name);

            // set back link
            // this.setHeaderBackLinkIfAccessible(unitData.data.portfolio_id);

            // add the portfolio in fav
            this._favouritesService.add(unitData.data, FavKeys.UNITS);

            this.setState({ ...unitData, tabs: [...this.getTabs(unitData.data.operationalState)] });
          } else {
            this.setState(unitData);
          }
        }),
      )
      .subscribe();
  }

  destroyUnitMqttStream(): void {
    this._unitMqttStore.destroyUnitMqttStream();
  }
  // updaters
  private readonly setUnitData = this.updater((state, data: Unit) => {
    return {
      ...state,
      tabs: [...this.getTabs(data.operationalState)],
      data,
    };
  });

  // private methods
  private getTabs(unitOperationalState: UnitOperationalStates): ToolbarInterface[] {
    if (
      unitOperationalState === UnitOperationalStates.DELETED ||
      unitOperationalState === UnitOperationalStates.PURGED
    ) {
      return TABS.filter((tab) => tab.name !== 'alerts' && tab.name !== 'extras');
    }

    return [...TABS];
  }

  private handleError(err: HttpErrorResponse, state: States = States.INITIAL_ERROR): Observable<UnitStateData> {
    const message = generateHttpErrorMessage(err, this._translationsService.instant(HTTP_ERRORS_TRANSLATION_HEADING));

    return of({
      status: state,
      error: message.message,
      data: null,
    });
  }
}
