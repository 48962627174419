import { Injectable } from '@angular/core';
import { RequestMethod } from '@class/commons/request-api.model';
import { ICollectionList, INewCollection, ICollection } from '@custom-types/collection/collection.model';
import { BaseApiService } from 'app/api-services/base.api-service';

export const collectionQueryKeys = {
  all: ['collection'] as const,
  collectionsList: () => [...collectionQueryKeys.all, 'collections-list'] as const,
  newCollection: () => [...collectionQueryKeys.all, 'new-collection'] as const,
  collectionDetail: (collectionUuid: string) =>
    [...collectionQueryKeys.all, 'collection-detail', collectionUuid] as const,
};
@Injectable({
  providedIn: 'root',
})
export class CollectionApiService extends BaseApiService {
  constructor() {
    super('/api/v1/portfolios');
  }

  collectionsList() {
    return this._query<ICollectionList[]>({
      queryKey: collectionQueryKeys.collectionsList(),
      queryFn: () =>
        this.requestObs({
          path: `/`,
          query: { simple: true },
        }),
    });
  }

  createNewCollection() {
    return this._mutation({
      mutationFn: (newCollection: INewCollection) =>
        this.requestObs<ICollectionList>({
          path: '/',
          method: RequestMethod.POST,
          data: newCollection,
        }),
      onSuccess: (newCollection) => {
        this._queryClient.setQueryData(collectionQueryKeys.collectionsList(), (oldData: ICollectionList[]) => {
          return [newCollection, ...oldData];
        });
      },
    });
  }

  collectionDetail(collectionUuid: string) {
    return this._query<ICollection>({
      queryKey: collectionQueryKeys.collectionDetail(collectionUuid),
      queryFn: () =>
        this.requestObs({
          path: `/${collectionUuid}/`,
        }),
    });
  }

  updateCollection(collectionUuid: string) {
    return this._mutation({
      mutationFn: (collection: Partial<ICollection>) =>
        this.requestObs<ICollection>({
          path: `/${collectionUuid}/`,
          method: RequestMethod.PUT,
          data: collection,
        }),
      onSuccess: (updatedCollection) => {
        this._queryClient.setQueryData(collectionQueryKeys.collectionDetail(collectionUuid), updatedCollection);
      },
    });
  }
}
