import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { apiStatus } from '../../classes/commons/common';
import { ApiWrapper, AvailableAPI, RequestMethod, UseHeaderType } from '../common/api-wrapper.service';
import { InviteRoleInterface } from '../../classes/commons/invite-role-interface';
import { TranslationsService } from './translations.service';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  // common properties like how many card you want to display on homepage, width,
  // height, anything or any number that is going to be used more frequent
  // These properties whould be in common class, haven't decided yet

  public PLATFORM_HEIGHT;
  public PLATFORM_WIDTH;

  public OPEN_DEVICE_CONTROLLER_IN_MODAL = false;
  public PLATFORM_RESIZED_OCCURRED_SUBJECT = new BehaviorSubject(false);
  public PLATFORM_RESIZED_OCCURRED = this.PLATFORM_RESIZED_OCCURRED_SUBJECT.pipe(debounceTime(500));

  public loggedInSubject: BehaviorSubject<boolean>;
  private _loggedIn: boolean;

  public set loggedIn(v: boolean) {
    this._loggedIn = v;
    this.loggedInSubject.next(this._loggedIn);
  }

  public get loggedIn(): boolean {
    return this._loggedIn;
  }

  constructor(
    private api: ApiWrapper,
    private translationsService: TranslationsService,
  ) {
    this.loggedInSubject = new BehaviorSubject(false);
  }

  // CommonFunctionsThatGonnaUseInOverAllApplication..... I guess functions should be in commonClass, not here, moved there

  // getting invites Roles list for unit / portfolio / vpp
  getInvitesRolesList(
    invitesObj: { roles: Array<InviteRoleInterface>; status: apiStatus },
    id: string,
    type: string,
    loader = null,
  ): void {
    invitesObj.status.reset();
    this.getInvitesRoles(id, type).then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        setTimeout(() => {
          if (loader) {
            loader.dismiss();
          }
        }, 500);
        invitesObj.status.$callMade = true;
        invitesObj.roles = response.data;
        if (response.data.length > 0) {
          // the names will be in english
          // iterate to set the names into required language
          invitesObj.roles.forEach((role: InviteRoleInterface) => {
            // /\s/g
            // The \s meta character in JavaScript regular expressions matches any
            // whitespace character: spaces, tabs, newlines and Unicode spaces.
            // And the g flag tells JavaScript to replace it multiple times.
            // If you miss it, it will only replace the first occurrence of the white space.
            //
            // The name we are getting from BE = 'Unit Owner (Basic)', 'Unit Owner (Analytics Bundle)', etc
            // replacing whitespace with _ to meet the variable in the translation file
            // In translation file we have: Unit_Owner_Basic, Unit_Owner_Analytics_Bundle, etc
            let roleName = role.name.replace(/\s/g, '');
            // could not generate a expression that will look for both opening bracket
            // and closing bracket
            // so doing it separately
            roleName = roleName.replace(/-/g, '');
            roleName = roleName.replace(/\(/g, '');
            roleName = roleName.replace(/\)/g, '');
            // this will get the translated role names that you can invite
            role.name = this.translationsService.instant(`InviteRoles.${roleName}`);
          });
          invitesObj.status.$dataExist = true;
        }
      },
      (error) => {
        if (loader) {
          loader.dismiss();
        }
        invitesObj.status.$callMade = true;
        invitesObj.status.$dataExist = false;
        invitesObj.status.$callFailed = true;
        invitesObj.status.$error = error;
      },
    );
  }

  public getPortfolioUsers(id = '') {
    const requestURL = '/api/v1/portfolios/' + id + '/users/';
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      requestURL,
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // id = encoded pk of unit / portfolio / vpp
  // type = vpp or portfolio or unit
  private getInvitesRoles(id: string, type: string) {
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v1/${type}/${id}/invitees/`,
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
    );
  }
}
