import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CommonService } from '../services/common/common.service';
import { StorageService } from '../services/common/storage.service';
import { PermissionKey } from '@class/commons/permissions/permission-constants';
import { PermissionsService } from '@service/permissions/permissions.service';
import { AnalyticsService } from '../services/common/analytics.service';
import { ProfileService } from '../services/profile/profile.service';

export enum RedirectCodeEnum {
  UNKNOWN = 0,
  NOT_LOGGED_IN = 1,
  MISSING_BASIC_PERM = 2,
}
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private common: CommonService,
    private storageService: StorageService,
    private permissionsService: PermissionsService,
    private analyticsService: AnalyticsService,
    private profileService: ProfileService,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean> {
    // return this.common.loggedIn;
    if (!this.common.loggedIn) {
      this.router.navigate(['account/login']);
      return false;
    } else {
      this.analyticsService.setUser(this.profileService.getUserProfile());

      // As the permissions are coming from database now
      // they are a bit changed now
      // the routes are not coming from the database
      // maybe we can implement that, but does that worth it?
      // so for the time being implemented this solution

      // I think profile and home are the url's that everyone can access
      // I am assuming but can change that if required
      if (next.routeConfig.path === 'home' || next.routeConfig.path === 'profile') {
        return true;
      }

      let routePermissions: Array<PermissionKey> = null;
      switch (next.routeConfig.path) {
        case 'portfolios':
        case 'portfolio/:id':
          routePermissions = [PermissionKey.GLOBAL_PORTFOLIO, PermissionKey.GLOBAL_UNIT];
          break;
        case 'virtual_power_plants':
        case 'virtualPowerPlant':
          routePermissions = [PermissionKey.GLOBAL_VPP];
          break;
        case 'units':
        case 'unit/:id':
          routePermissions = [PermissionKey.GLOBAL_UNIT];
          break;
        case 'configure':
          routePermissions = [PermissionKey.GLOBAL_INSTALLER];
          break;
        case 'roles_permission':
          routePermissions = [PermissionKey.GLOBAL_STAFF];
          break;
        case 'admin_devices':
          routePermissions = [PermissionKey.GLOBAL_STAFF];
          break;
        case 'organisation-settings/:uuid':
          routePermissions = [PermissionKey.GLOBAL_ORGANISATION];
          break;
      }

      if (this.permissionsService.any(routePermissions)) {
        return true;
      } else {
        this.common.loggedIn = false;
        await this.storageService.clearLocalAndSessionStorage();
        this.router.navigate([`account/login/${RedirectCodeEnum.MISSING_BASIC_PERM}`]);
        return false;
      }
    }
  }
}
