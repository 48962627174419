import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, distinctUntilKeyChanged, filter, first, firstValueFrom, map, tap } from 'rxjs';
import { IMqttMessage } from 'ngx-mqtt';
import { ApiWrapper } from '../common/api-wrapper.service';
import { AvailableAPI, RequestMethod, UseHeaderType } from '../../classes/commons/request-api.model';
import { Unit } from '../../classes/commons/unit.model';
import { apiStatus } from '../../classes/commons/common';
import { AlertsService } from '../alerts/alerts.service';
import { FavKeys, FavouritesService } from '../common/favourites.service';
import { InviteRoleInterface } from '../../classes/commons/invite-role-interface';
import { isEmpty } from 'lodash';
import { PermissionsService } from '@service/permissions/permissions.service';
import { PermissionArea, PermissionKey } from '@class/commons/permissions/permission-constants';
import {
  EndpointControllerParameterDTO,
  EndpointControllerTypeDTO,
  EndpointDTO,
} from '@class/units/endpoint/endpoint-payload.model';
import { BrowserLogger } from '@class/core/browser-logger';
import { Endpoint } from '@service/units/units.types';

import { NgxMqttWrapperService } from '@service/core/ngx-mqtt-wrapper.service';
import { UnitStore } from './unit.store';
import { EndpointsApi } from 'app/api/units/endpoints.api';
import { filterSuccessResult, injectQueryClient } from '@ngneat/query';
import { queryKeys as unitsQueryKeys } from 'app/api-services/units/units.api-service';
import { queryKeys as devicesQueryKeys } from '@service/devices/devices.service';
import { queryKeys as endpointsQueryKeys } from 'app/api-services/endpoints.api-service';
import { TimePeriodResolution } from '@class/commons/constants-datetime';
import { ChartdataApiService } from 'app/api-services/chartdata.api-service';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class UnitsService {
  // PROPERTIES
  // only used in vpp atm
  unitsList = {
    units: [], // all the UNITS   list
    apiCall: false, // if the api call has been made or not
    dataExist: false, // data is null or undefined
    apiCallFailed: false, // if by any chance the api call didn't go through, internet is down or server error
    error: {}, // if any error occurred,
  };
  IsScadaUnitsExistInTheList = true;
  unitEndpoints = {
    alertMetrics: [],
    endpoints: [],
    apiCall: false,
    dataExist: false,
    apiCallFailed: false,
    error: {},
    deviceHealthStatsExist: false,
  };

  unitMetricsMqtt = {
    mqttMsg: {},
    mqttSubject: new BehaviorSubject({}),
  };
  unitMetricsMqttSubscription;
  metricsLookup = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  liveData: any = {};
  unitSiteControllers = {
    controllers: [] as EndpointControllerTypeDTO[],
    status: new apiStatus(),
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public selectedUnit: any = {};

  unitInvitesRoles = {
    roles: Array<InviteRoleInterface>(),
    status: new apiStatus(),
  };

  // used to notify settings component that the endpoints/users are now available
  usersReady = new BehaviorSubject(null);
  private endpointsReady = new BehaviorSubject(null);

  endpointsReady$: Observable<{ endpoints: EndpointDTO[] }> = this.endpointsReady.asObservable();
  selectedUnitDeleted = new BehaviorSubject(null);
  private allUnitsSubject = new BehaviorSubject<Unit[]>(null);
  allUnits$: Observable<Unit[]> = this.allUnitsSubject.asObservable();
  unitUUIDmetricsMqttMsg;

  serviceInitialized = false;
  currentUserLevel: number = 0;
  currentUserPriority: number = 0;

  private triggerDropletUpdate: BehaviorSubject<string> = new BehaviorSubject('');
  triggerDropletUpdate$ = this.triggerDropletUpdate.asObservable();

  private _endpointsApi = inject(EndpointsApi);
  private _chartdataApiService = inject(ChartdataApiService);

  private _queryClient = injectQueryClient();

  constructor(
    private api: ApiWrapper,
    private permissionsService: PermissionsService,
    private alertsService: AlertsService,
    private favouritesService: FavouritesService,
    private _ngxMqttWrapper: NgxMqttWrapperService,
    private _unitStore: UnitStore,
  ) {
    this._unitStore.unit$
      .pipe(
        filter((unitData) => unitData.data !== null),
        distinctUntilKeyChanged('data', (prev, curr) => prev.uuid === curr.uuid),
        tap((unitData) => {
          this.getSelectedUnitDataAndEstablishMqtt(unitData.data, true);
        }),
      )
      .subscribe();
  }

  resetService() {
    BrowserLogger.log('UnitsService.resetService');
    this.destroyUnitMetricMqttSubscription();
    this.unitsList = {
      units: [],
      apiCall: false,
      dataExist: false,
      apiCallFailed: false,
      error: {},
    };
    this.IsScadaUnitsExistInTheList = false;

    this.unsubscribeFromEndpointControlSubscriptions(this.unitEndpoints.endpoints);

    this.unitEndpoints = {
      alertMetrics: [],
      endpoints: [],
      apiCall: false,
      dataExist: false,
      apiCallFailed: false,
      error: {},
      deviceHealthStatsExist: false,
    };

    this.unitMetricsMqtt = {
      mqttMsg: {},
      mqttSubject: new BehaviorSubject({}),
    };
    this.metricsLookup = {};
    this.liveData = {};

    this.unitSiteControllers = {
      controllers: [],
      status: new apiStatus(),
    };
    this.selectedUnit = {};
    this.unitInvitesRoles = {
      roles: Array<InviteRoleInterface>(),
      status: new apiStatus(),
    };

    this.usersReady = new BehaviorSubject(null);
    this.emitEndpointsReady(null);
    this.unitUUIDmetricsMqttMsg = undefined;
    this.serviceInitialized = false;
    this.alertsService.resetService();
    this.permissionsService.clear(PermissionArea.UNIT);
    this.currentUserLevel = 0;
    this.currentUserPriority = 0;
  }

  public updateDroplet(uuid: string) {
    BrowserLogger.log('UnitsService.updateDroplet', { uuid });
    this.triggerDropletUpdate.next(uuid);
  }

  // only from vpp
  public allUnits() {
    BrowserLogger.log('UnitsService.allUnits');
    this.unitsList.apiCall = false;
    this.unitsList.dataExist = false;
    this.unitsList.apiCallFailed = false;
    this.getAllUnits().then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        this.unitsList.apiCall = true;
        if (response.data.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.unitsList.units = response.data.map((data: any) => new Unit(data));
          const temp = this.unitsList.units.filter((unit) => unit.isScadaUnit);
          this.IsScadaUnitsExistInTheList = temp.length > 0;
          this.unitsList.dataExist = true;
        } else {
          this.unitsList.units = [];
          this.unitsList.dataExist = false;
        }
        this.allUnitsSubject.next(this.unitsList.units);
      },
      () => {
        this.unitsList.apiCallFailed = true;
      },
    );
  }

  private async getEndpointsOfSelectedUnitIfHasPermission(): Promise<void> {
    if (this.permissionsService.any([PermissionKey.UNIT_VIEW_DEVICE, PermissionKey.UNIT_VIEW_CONTROLLER])) {
      return this.getEndpointsOfSelectedUnit();
    }
  }

  private unsubscribeFromEndpointControlSubscriptions(endpoints) {
    endpoints.forEach((endpoint) => {
      this._ngxMqttWrapper.unsubscribe(endpoint?.deviceControlMqttSubs);
    });
  }

  private updateQueryClientData(unitId: string, endpoints: Endpoint[]) {
    this._queryClient.setQueryData(unitsQueryKeys.endpoints({ unitId }), endpoints);

    endpoints.forEach((endpoint) => {
      this._queryClient.setQueryData(endpointsQueryKeys.detail({ endpointUuid: endpoint.long_uuid }), endpoint);

      endpoint.devices.forEach((device) => {
        if (device.id) {
          this._queryClient.setQueryData(devicesQueryKeys.detail({ id: device.id }), device);
        }
      });
    });
  }

  public async getEndpointsOfSelectedUnit(loader = null): Promise<void> {
    BrowserLogger.log('UnitsService.getEndpointsOfSelectedUnit', { selectedUnitId: this.selectedUnit?.id });
    this.unitEndpoints.apiCall = false;
    this.unitEndpoints.dataExist = false;
    this.unitEndpoints.apiCallFailed = false;
    this.unitEndpoints.alertMetrics = [];
    try {
      const response = await this._endpointsApi.getUnitEndpoints(this.selectedUnit.id);

      this.updateQueryClientData(this.selectedUnit.id, response.data);

      this.unitEndpoints.apiCall = true;

      if (loader) {
        loader.dismiss();
      }

      // check if there is data previously in unitEndpoints
      // then need to end subscriptions for endpoint controls
      // this is getting created in handleDeviceControlMqtt
      this.unsubscribeFromEndpointControlSubscriptions(this.unitEndpoints.endpoints);

      if (response.data.length > 0) {
        this.unitEndpoints.endpoints = response.data;
        this.createMetricKeyOfDevices(this.unitEndpoints.endpoints);
        this.unitEndpoints.deviceHealthStatsExist = false;
        this.unitEndpoints.endpoints.forEach((endpoint, index) => {
          endpoint.triggerFirmwareUpdate = this.endpointNeedToUpdate(endpoint);
          endpoint.clicked = true; // expand controller list by default

          // temp check here to check whether health exist in devices or not
          const attributeDevices = endpoint.endpoint_attributes?.devices;
          if (!isEmpty(attributeDevices)) {
            for (const key in attributeDevices) {
              if (attributeDevices[key]['health']) {
                this.unitEndpoints.deviceHealthStatsExist = true;
                break;
              }
            }
          }

          this.setEndpointControllerValuesAndKeys(endpoint);
          const lastSeen = DateTime.fromISO(endpoint['last_seen']);
          endpoint.lastSeen = lastSeen.toRelative();
          endpoint.dropletIndex = index + 1;
          this.handleDeviceControlMqtt(endpoint);
          endpoint.devices.forEach((device) => {
            device.controls.forEach((control) => {
              this.controlSettings(control, device.number);
            });
            if (device.settings) {
              device.settings.forEach((setting) => {
                this.controlSettings(setting, device.number);
              });
            }
          });
        });
        this.unitEndpoints.dataExist = true;
      } else {
        this.unitEndpoints.endpoints = [];
        this.unitEndpoints.dataExist = false;
      }
      this.emitEndpointsReady(this.unitEndpoints);
    } catch (error) {
      this.unitEndpoints.apiCall = true;
      this.unitEndpoints.apiCallFailed = true;
      this.unitEndpoints.error = error;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private emitEndpointsReady(data: any): void {
    BrowserLogger.log('UnitsService.emitEndpointsReady', { data });
    this.endpointsReady.next(data);
  }

  //TODO: Need to add this into the endpoint model
  endpointNeedToUpdate(endpoint): boolean {
    BrowserLogger.log('UnitsService.endpointNeedToUpdate', { endpoint });
    // checking here whether a droplet needs to update or not
    // droplet needs to be on minimum 2.6.0 version to support this update
    // otherwise it's not gonna work
    let temp = '2.6.0'.split('.');
    const minVersion = temp.map((x) => Number(x));

    temp = endpoint.software_version_frontend ? endpoint.software_version_frontend.split('.') : [null, null, null];
    const currentVersion = temp.map((x) => Number(x));

    const currentVersionIsGreaterOrEqualToMinVersion =
      currentVersion[0] >= minVersion[0] && currentVersion[1] >= minVersion[1] && currentVersion[2] >= minVersion[2];

    const frontendVersionEmptyOrNull =
      endpoint.software_version_frontend === null || endpoint.software_version_frontend === '';

    return (
      endpoint.software_version !== endpoint.target_software_version &&
      endpoint.target_software_version !== null &&
      (frontendVersionEmptyOrNull || currentVersionIsGreaterOrEqualToMinVersion)
    );
  }

  setEndpointControllerValuesAndKeys(endpoint) {
    BrowserLogger.log('UnitsService.setEndpointControllerValuesAndKeys', { endpoint });
    if (endpoint.controllers) {
      endpoint.controllers.forEach((controller) => {
        this.makeControllerParamJson(controller);
        this.makeControllerControlKeys(controller);
      });
    }
  }
  makeControllerParamJson(controller) {
    BrowserLogger.log('UnitsService.makeControllerParamJson', { controller });
    if (controller.parameters) {
      controller.parameters.forEach((param) => {
        param.display_config_json = JSON.parse(param.display_config);
      });
    }
  }
  makeControllerControlKeys(controller) {
    BrowserLogger.log('UnitsService.makeControllerControlKeys', { controller });
    if (controller.control_keys) {
      controller.control_keys.forEach((control) => {
        control.display_config_json = JSON.parse(control.display_config);
        control.statusKeyWithDeviceNumber = control.status_key;
        control.setKey = control.set_key;
        // checking the value from mqtt stream and setting it as we are doing it for devices
        if (this.unitUUIDmetricsMqttMsg !== undefined) {
          control.statusValueFromMqtt = this.unitUUIDmetricsMqttMsg[control.statusKeyWithDeviceNumber];
          control.statusValue = control.statusValueFromMqtt;
        }
      });
    }
  }

  // Here, getting each control, device controls. and generating the mqtt key to read value from mqtt stream
  // the key contains `{0}`, need to replace it with device number.
  // some keys are coming from droplet that don't replace `{0}` with device number if the device number is 1
  // just fixing those things here
  private controlSettings(control, deviceNumber) {
    BrowserLogger.log('UnitsService.controlSettings', { control, deviceNumber });
    control.setKey =
      control.set_key.indexOf('{0}') === -1 ? control.set_key : control.set_key.replace('{0}', deviceNumber);
    let statusKey = '',
      statusKeyWithDeviceNumber = '';
    if (control.status_key.indexOf('{0}') === -1) {
      statusKey = control.status_key;
      statusKeyWithDeviceNumber = control.status_key;
    } else {
      statusKeyWithDeviceNumber = control.status_key.replace('{0}', String(deviceNumber));
      statusKey = control.status_key.replace('{0}', '');
    }
    control.statusKey = statusKey;
    control.statusKeyWithDeviceNumber = statusKeyWithDeviceNumber;
    control.display_config_json = JSON.parse(control.display_config);
    // tslint:disable-next-line: triple-equals
    if (this.unitUUIDmetricsMqttMsg != undefined) {
      if (deviceNumber > 1) {
        control.statusValueFromMqtt = this.unitUUIDmetricsMqttMsg[statusKeyWithDeviceNumber];
      } else {
        control.statusValueFromMqtt =
          this.unitUUIDmetricsMqttMsg[statusKeyWithDeviceNumber] !== undefined &&
          this.unitUUIDmetricsMqttMsg[statusKeyWithDeviceNumber] != null
            ? this.unitUUIDmetricsMqttMsg[statusKeyWithDeviceNumber]
            : this.unitUUIDmetricsMqttMsg[statusKey];
      }
      if (Object.hasOwn(control, 'current_value')) {
        // means it's a device setting control
        control.statusValue = control.current_value;
      } else {
        control.statusValue = control.statusValueFromMqtt;
      }
    }
  }

  private createMetricKeyOfDevices(endpoints) {
    BrowserLogger.log('UnitsService.createMetricKeyOfDevices', { endpoints });
    endpoints.forEach((endpoint) => {
      endpoint.devices.forEach((device) => {
        // check if the device is valid to view in the stream tab
        // devices like droplet health stat shouldn't be there
        device.viewInStreamTab = !device.device_type_name.toUpperCase().includes('DROPLET HEALTH STATS');
        device.metrics.forEach((metric) => {
          metric.values = [];
          metric.times = [];
          metric.uniqueId = endpoint.id + '_' + device.id + '_' + metric.id;
          metric.endpoint_id = endpoint.id;
          metric.unit_uuid = endpoint.unit_uuid;
          metric.viewInStreamTab = false;

          const nameToSubtract = this.getNameToSubtract(metric.name, device);
          // checking here for a fail safe if any of the top scenario fail
          metric.streamName =
            nameToSubtract.length > 0
              ? metric.name.substring(metric.name.indexOf(nameToSubtract) + nameToSubtract.length)
              : metric.name.indexOf(' - ') >= 0
                ? metric.name.substring(metric.name.indexOf(' - ') + 3)
                : metric.name;
          if (device.number > 1) {
            metric.metricKey = metric.key.includes('{0}') ? metric.key.replace('{0}', device.number) : metric.key;
          } else {
            metric.metricKey = metric.key.includes('{0}') ? metric.key.replace('{0}', device.number) : metric.key;
            metric.metricKeyWithoutDeviceNumber = metric.key.includes('{0}')
              ? metric.key.replace('{0}', '')
              : metric.key;
          }
          // this is just temporary, will remove this once we have api in production
          metric.name = `${device.name ?? device.full_name ?? device.short_name} - ${metric.streamName}`;
          this.unitEndpoints.alertMetrics.push(metric);
        });
      });
      endpoint.controllers.forEach((controller) => {
        // check if the device is valid to view in the stream tab
        // devices like droplet health stat shouldn't be there
        controller.full_name = controller.name + ' - ' + controller.controller_type;
        controller.viewInStreamTab = true;
        controller.control_metrics.forEach((metric) => {
          metric.values = [];
          metric.times = [];
          metric.uniqueId = endpoint.id + '_' + controller.controller_unique_id + '_' + metric.id;
          metric.endpoint_id = endpoint.id;
          metric.unit_uuid = endpoint.unit_uuid;
          metric.viewInStreamTab = false;
          metric.streamName =
            metric.name.indexOf(' - ') >= 0 ? metric.name.substring(metric.name.indexOf(' - ') + 3) : metric.name;
          metric.metricKey = metric.key;
          metric.metricKeyWithoutDeviceNumber = metric.key;
          // will enable this if need to add these metrics in alerts section
          // this.unitEndpoints.alertMetrics.push(controlKey);
        });
        controller.metrics = controller.control_metrics;
      });
    });
    if (!isEmpty(this.unitMetricsMqtt.mqttMsg)) {
      this.getValuesOfDevicesMetrics();
    }
  }

  // TODO: need to type it when devices PR is merged
  private getNameToSubtract(metricName: string, device): string {
    BrowserLogger.log('UnitsService.getNameToSubtract', { metricName, device });
    // didn't like this, but have no other option - need to ask backend to fix this issue
    //
    // when backend send data for metric name
    // it marry the (device.full_name || device.name || device.short_name) with metric.name
    // and we don't want to show the device name for each metric under a device on stream tab
    // to avoid that, we were slicing it up with metric.name.substring(metric.name.indexOf(' - ') + 3)
    // but then people started adding the hyphen(-) sign in the device name
    // making the slicing non-valid
    // so doing this now, not sure how long it will last
    let nameToSub = '';
    if (metricName.includes(device.name)) {
      nameToSub = this.getDeviceNameStringFromMetric(metricName, device.name);
    } else if (metricName.includes(device.full_name)) {
      nameToSub = this.getDeviceNameStringFromMetric(metricName, device.full_name);
    } else if (metricName.includes(device.short_name)) {
      nameToSub = this.getDeviceNameStringFromMetric(metricName, device.short_name);
    }
    return nameToSub;
  }

  private getDeviceNameStringFromMetric(metricName: string, deviceName: string): string {
    let result = '';
    if (metricName.includes(deviceName + ' - ')) {
      result = deviceName + ' - ';
    } else if (metricName.includes(deviceName)) {
      result = deviceName;
    }
    BrowserLogger.log('UnitsService.getDeviceNameStringFromMetric', { result, metricName, deviceName });
    return result;
  }

  private handleDeviceControlMqtt(endpoint) {
    BrowserLogger.log('UnitsService.handleDeviceControlMqtt', { endpoint });
    endpoint.deviceControlMqttMsgs = [];
    endpoint.deviceControlMqttSubs = this._ngxMqttWrapper
      .observe('devices/' + endpoint.long_uuid + '/control')
      .subscribe((message: IMqttMessage) => {
        const msg = JSON.parse(message.payload.toString());
        BrowserLogger.log('UnitsService.handleDeviceControlMqtt.message', { msg });
        endpoint.deviceControlMqttMsgs.push({
          controlName: msg.control_name,
          controlValue: msg.control_value,
          time: DateTime.now().toFormat('DD/MM/YY HH:mm:ss'),
        });
      });
  }

  handleUnitMetricMqttMessage(selected_unit_uuid?: string) {
    const uuid = selected_unit_uuid ?? this.selectedUnit?.uuid;
    BrowserLogger.log('UnitsService.handleUnitMetricMqttMessage', { uuid });
    this.unitMetricsMqttSubscription = this._ngxMqttWrapper
      .observe(`${uuid}/metrics`)
      .subscribe((mqttMessage: IMqttMessage) => {
        const message = JSON.parse(mqttMessage.payload.toString());
        BrowserLogger.log('UnitsService.handleUnitMetricMqttMessage.msg', {
          message,
          uuid,
          mqttMessage,
        });
        this.unitUUIDmetricsMqttMsg = message;
        this.emitUnitMetricsMqttMsg(this.unitUUIDmetricsMqttMsg);
        this.handleUnitDeviceControlsStatus(this.unitUUIDmetricsMqttMsg);
        this.unitMetricsMqtt.mqttMsg = message;
        if (this.unitEndpoints.endpoints.length > 0) {
          setTimeout(() => {
            this.getValuesOfDevicesMetrics();
          }, 500);
        }
      });
  }

  // only getting used in device controls... can replace it with unit-mqtt-store
  private emitUnitMetricsMqttMsg(msg): void {
    BrowserLogger.log('UnitsService.emitUnitMetricsMqttMsg', { msg });
    this.unitMetricsMqtt.mqttSubject.next(this.unitUUIDmetricsMqttMsg);
  }

  private handleUnitDeviceControlsStatus(msg) {
    BrowserLogger.log('UnitsService.handleUnitDeviceControlsStatus', { msg });
    if (this.unitEndpoints.apiCall && this.unitEndpoints.dataExist) {
      this.unitEndpoints.endpoints.forEach((endpoint) => {
        endpoint.devices.forEach((device) => {
          device.controls.forEach((control) => {
            this.checkDeviceControlStatus(control, msg, device.number);
          });
          if (device.settings) {
            device.settings.forEach((setting) => {
              this.checkDeviceControlStatus(setting, msg, device.number);
            });
          }
        });
        endpoint.controllers.forEach((controller) => {
          if (!(controller.control_keys === undefined || controller.control_keys === null)) {
            controller.control_keys.forEach((controlKey) => {
              this.checkControllerControlKeyStatus(controlKey, msg);
            });
          }
        });
      });
    }
  }

  // have to check the value for each device control / settings
  // this function is accepting control, msg from mqtt and device number
  // for some devices, device number = 1, the key is bit different as explained above while making these keys
  // reading the latest value plus checking whether we are waiting for the new value or not
  // if we have updated any value for the control
  private checkDeviceControlStatus(control, msg, deviceNumber) {
    if (deviceNumber > 1) {
      if (msg[control.statusKeyWithDeviceNumber] !== undefined) {
        control.statusValueFromMqtt = msg[control.statusKeyWithDeviceNumber];
      }
    } else {
      if (msg[control.statusKeyWithDeviceNumber] !== undefined || msg[control.statusKey] !== undefined) {
        control.statusValueFromMqtt =
          msg[control.statusKeyWithDeviceNumber] !== undefined && msg[control.statusKeyWithDeviceNumber] != null
            ? msg[control.statusKeyWithDeviceNumber]
            : msg[control.statusKey];
      }
    }
    if (control.waitingForControlUpdate) {
      if (control.statusValue != control.statusValueFromMqtt) {
        control.confirmationAttempts++;
      } else {
        control.statusValue = control.statusValueFromMqtt;
        control.waitingForControlUpdate = false;
        control.confirmationStatus = true;
        control.valueChange = false;
        if (control.confirmationTimeout) {
          clearTimeout(control.confirmationTimeout);
        }
      }
    } else {
      if (!control.valueChange && !Object.hasOwn(control, 'current_value')) {
        control.statusValue = control.statusValueFromMqtt;
      }
    }
    // control.statusValue = control.statusValueFromMqtt;
    //BrowserLogger.log('UnitsService.checkDeviceControlStatus', { control, msg, deviceNumber });
  }

  private checkControllerControlKeyStatus(control, msg) {
    if (msg[control.statusKeyWithDeviceNumber] !== undefined) {
      control.statusValueFromMqtt = msg[control.statusKeyWithDeviceNumber];
    }
    if (control?.display_config_json?.numeric?.nullable && msg[control.statusKeyWithDeviceNumber] == null) {
      control.statusValueFromMqtt = 'Auto';
    }
    if (control.waitingForControlUpdate) {
      if (
        control?.display_config_json?.numeric?.nullable &&
        control.statusValue == null &&
        control.statusValueFromMqtt === 'Auto'
      ) {
        control.statusValue = control.statusValueFromMqtt;
        control.waitingForControlUpdate = false;
        control.confirmationStatus = true;
        control.valueChange = false;
        if (control.confirmationTimeout) {
          clearTimeout(control.confirmationTimeout);
          control.confirmationTimeout = null;
        }
      } else if (control.statusValue != control.statusValueFromMqtt) {
        control.confirmationAttempts++;
      } else {
        control.statusValue = control.statusValueFromMqtt;
        control.waitingForControlUpdate = false;
        control.confirmationStatus = true;
        control.valueChange = false;
        if (control.confirmationTimeout) {
          clearTimeout(control.confirmationTimeout);
        }
      }
    } else {
      if (!control.valueChange) {
        control.statusValue = control.statusValueFromMqtt;
      }
    }
    // control.statusValue = control.statusValueFromMqtt;
    //BrowserLogger.log('UnitsService.checkControllerControlKeyStatus', { control, msg });
  }

  private getValuesOfDevicesMetrics() {
    this.unitEndpoints.endpoints.forEach((endpoint) => {
      endpoint.devices.forEach((device) => {
        if (this.unitMetricsMqtt.mqttMsg['droplet_timestamp']) {
          if (!device.FirstSeen) {
            device.FirstSeen = new Date(this.unitMetricsMqtt.mqttMsg['droplet_timestamp'] * 1000);
          }
          device.lastSeen = new Date(this.unitMetricsMqtt.mqttMsg['droplet_timestamp'] * 1000);
        }
        device.metrics.forEach((metric) => {
          if (device.number > 1) {
            if (Object.hasOwn(this.unitMetricsMqtt.mqttMsg, metric.metricKey)) {
              metric.viewInStreamTab = true;
              metric.valueUpdated = !metric.valueUpdated;
              metric.lastValue = this.unitMetricsMqtt.mqttMsg[metric.metricKey];
              if (metric.lastValue) {
                metric.lastValue = metric.lastValue.toFixed(2);
              }
              metric.values.push(metric.lastValue);
              const timestamp = this.unitMetricsMqtt.mqttMsg['droplet_timestamp'] * 1000;
              metric.times.push(!isNaN(timestamp) && timestamp > 0 ? new Date(timestamp) : new Date());
            }
          } else {
            if (
              Object.hasOwn(this.unitMetricsMqtt.mqttMsg, metric.metricKey) ||
              Object.hasOwn(this.unitMetricsMqtt.mqttMsg, metric.metricKeyWithoutDeviceNumber)
            ) {
              metric.viewInStreamTab = true;
              metric.valueUpdated = !metric.valueUpdated;
              metric.lastValue =
                this.unitMetricsMqtt.mqttMsg[metric.metricKey] != null &&
                this.unitMetricsMqtt.mqttMsg[metric.metricKey] !== undefined
                  ? this.unitMetricsMqtt.mqttMsg[metric.metricKey]
                  : this.unitMetricsMqtt.mqttMsg[metric.metricKeyWithoutDeviceNumber];
              if (metric.lastValue) {
                metric.lastValue = metric.lastValue.toFixed(2);
              }
              metric.values.push(metric.lastValue);
              const timestamp = this.unitMetricsMqtt.mqttMsg['droplet_timestamp'] * 1000;
              metric.times.push(!isNaN(timestamp) && timestamp > 0 ? new Date(timestamp) : new Date());
            }
          }
          // this is commented out for now because popping out oldest metric will cause x axis ranges to be inconsistent between plots
          // while (metric.values.length > 99) {
          //   metric.values.shift();
          // }
          // while (metric.times.length > 99) {
          //   metric.times.shift();
          // }
        });
        device.metricsForStreamTab = device.metrics.filter((metric) => metric.viewInStreamTab);
      });
      endpoint.controllers.forEach((controller) => {
        if (this.unitMetricsMqtt.mqttMsg['droplet_timestamp']) {
          if (!controller.FirstSeen) {
            controller.FirstSeen = new Date(this.unitMetricsMqtt.mqttMsg['droplet_timestamp'] * 1000);
          }
          controller.lastSeen = new Date(this.unitMetricsMqtt.mqttMsg['droplet_timestamp'] * 1000);
        }
        if (controller.metrics) {
          controller.metrics.forEach((metric) => {
            if (Object.hasOwn(this.unitMetricsMqtt.mqttMsg, metric.metricKey)) {
              metric.viewInStreamTab = true;
              metric.valueUpdated = !metric.valueUpdated;
              metric.lastValue = this.unitMetricsMqtt.mqttMsg[metric.metricKey];
              if (metric.lastValue) {
                metric.lastValue = metric.lastValue.toFixed(2);
              }
              metric.values.push(metric.lastValue);
              const timestamp = this.unitMetricsMqtt.mqttMsg['droplet_timestamp'] * 1000;
              metric.times.push(!isNaN(timestamp) && timestamp > 0 ? new Date(timestamp) : new Date());
            }
            // this is commented out for now because popping out oldest metric will cause x axis ranges to be inconsistent between plots
            // while (metric.values.length > 99) {
            //   metric.values.shift();
            // }
            // while (metric.times.length > 99) {
            //   metric.times.shift();
            // }
          });
          controller.metricsForStreamTab = controller.metrics.filter((metric) => metric.viewInStreamTab);
        }
      });
    });
    BrowserLogger.log('UnitsService.getValuesOfDevicesMetrics', { endpoints: this.unitEndpoints.endpoints });
  }

  public destroyUnitMetricMqttSubscription() {
    BrowserLogger.log('UnitsService.destroyUnitMetricMqttSubscription');
    this.unitMetricsMqtt.mqttSubject.next(null);
    if (this.unitMetricsMqttSubscription) {
      this._ngxMqttWrapper.unsubscribe(this.unitMetricsMqttSubscription);
    }
    this.unitUUIDmetricsMqttMsg = undefined;
  }

  private getSelectedUnitDataAndEstablishMqtt(unit: Unit, callRemainingFunctionsToGetUnitData = false): void {
    BrowserLogger.log('UnitsService.getSelectedUnitDataAndEstablishMqtt', unit.id);
    this.selectedUnit = { ...unit };

    if (callRemainingFunctionsToGetUnitData) {
      this.getEndpointsOfSelectedUnitIfHasPermission();
      this.getSiteControllersIfHasPermission();
    }
    this.handleUnitMetricMqttMessage(this.selectedUnit.uuid);
  }

  private getSiteControllersIfHasPermission(): void {
    if (this.permissionsService.any([PermissionKey.UNIT_VIEW_CONTROLLERTYPE])) {
      this.getSiteControllers();
    }
  }

  getSiteControllers(loader = null): void {
    this.unitSiteControllers.status.reset();
    this.siteControllers().then(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (response: any) => {
        // dismiss and existing loader popup
        setTimeout(() => {
          if (loader) {
            loader.dismiss();
          }
        }, 500);
        // process response
        this.unitSiteControllers.status.$callMade = true;
        this.unitSiteControllers.controllers = response.data;
        if (response.data && response.data.length > 0) {
          this.unitSiteControllers.status.$dataExist = true;
          this.unitSiteControllers.controllers.forEach((controller) => {
            controller.controller_type_parameters.forEach((param) => {
              param.display_config_json = JSON.parse(param.display_config);
              const defaultVal = EndpointControllerParameterDTO.getDefaultValue(param);
              if (defaultVal !== undefined) {
                param.value = defaultVal;
              }
            });
          });
        }
        BrowserLogger.log('UnitsService.getSiteControllers', { unitSiteControllers: this.unitSiteControllers });
      },
      (error) => {
        if (loader) {
          loader.dismiss();
        }
        this.unitSiteControllers.status.$callMade = true;
        this.unitSiteControllers.status.$dataExist = false;
        this.unitSiteControllers.status.$callFailed = true;
        this.unitSiteControllers.status.$error = error;
      },
    );
  }

  // API CALLS
  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getSimpleListOfUnits(): Promise<any> {
    BrowserLogger.log('UnitsService.getSimpleListOfUnits');
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/units/?simple=true?',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getAllUnits(): Promise<any> {
    BrowserLogger.log('UnitsService.getAllUnits');
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/unitssummary/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // get unit detail by unit id
  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getUnitDetails(unitid: any): Promise<any> {
    BrowserLogger.log('UnitsService.getUnitDetails', { unitid });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/units/' + unitid + '/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // get unit detail by unit id
  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getUnitHistory(unitId: any, from: number = null, to: number = null, granularity: string = null): Promise<any> {
    BrowserLogger.log('UnitsService.getUnitHistory', { unitId });
    let suffix = (from && to) || granularity ? '?' : '';
    suffix += granularity ? `period=${granularity}&` : '';
    suffix += from && to ? `fromts=${from}&tots=${to}` : '';

    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v1/units/${unitId}/history/${suffix}`,
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // get unit detail by unit id
  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updateUnitDetails(unitId: string, data: any): Promise<any> {
    BrowserLogger.log('UnitsService.updateUnitDetails', { unitId });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/units/' + unitId + '/',
      RequestMethod.PATCH,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getUnitUsers(unitId: any): Promise<any> {
    BrowserLogger.log('UnitsService.getUnitUsers', { unitId });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/units/' + unitId + '/users/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getUnitUsersAfterRemove(unitId: any): Promise<any> {
    BrowserLogger.log('UnitsService.getUnitUsersAfterRemove', { unitId });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/units/' + unitId + '/users/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public removeUnitUser(membershipId: any): Promise<any> {
    BrowserLogger.log('UnitsService.removeUnitUser', { membershipId });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/unit-memberships/' + membershipId + '/',
      RequestMethod.DELETE,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async deleteUnit(unit_id: string): Promise<any> {
    BrowserLogger.log('UnitsService.deleteUnit', { unit_id });
    try {
      await this.favouritesService.remove({ id: unit_id }, FavKeys.UNITS);
      this.selectedUnitDeleted.next(unit_id);
      return this.api.handleRequest(
        AvailableAPI.SWITCHDIN,
        `/api/v1/units/${unit_id}/`,
        RequestMethod.DELETE,
        UseHeaderType.AUTHORIZED_SWDIN,
        {},
      );
    } catch (err) {
      console.log(err);
    }
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public inviteUnitUser(unitId: any, data: any): Promise<any> {
    BrowserLogger.log('UnitsService.inviteUnitUser', { unitId });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/units/' + unitId + '/invite_user/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public logMessages(unitId): Promise<any> {
    BrowserLogger.log('UnitsService.logMessages', { unitId });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/units/' + unitId + '/log_messages/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public deviceEvents(unitId, pageNumber): Promise<any> {
    BrowserLogger.log('UnitsService.deviceEvents', { unitId, pageNumber });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/events/?page=' + pageNumber + '&unit=' + unitId,
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // Get data set for the selected metrics : units => performance tab
  // todo - strongly typed return value
  /**
    @param field Database column to be queried.
    @param uuid uuid of type 'field'
    @param metrics Comma seperated tuples: (metric_key_1),(metric_key_2)...
    @param startTime Time (Unix): 'From' timestamp
    @param endTime Time (Unix): 'To' timestamp
    @param period String: 'minute', 'hour', or 'day'
    */
  public getMetricData(
    field: 'unit_uuid' | 'portfolio_uuid',
    uuid: string,
    metrics: string[],
    startTime: number,
    endTime: number,
    period: TimePeriodResolution.MINUTE | TimePeriodResolution.HOUR | TimePeriodResolution.DAY,
  ) {
    BrowserLogger.log('UnitsService.getMetricData', {
      unitOrPortfolio: field,
      uuid,
      key: metrics,
      startTime,
      endTime,
      resolution: period,
    });

    const result = this._chartdataApiService
      .getChartdata({
        field,
        uuid,
        metrics,
        period,
        from: startTime,
        to: endTime,
      })
      .result$.pipe(
        filterSuccessResult(),
        first(),
        map((res) => res.data),
      );

    return firstValueFrom(result);
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public siteControllers(): Promise<any> {
    BrowserLogger.log('UnitsService.siteControllers');
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/site-controllers/controller-types/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public createNewEndpointController(data): Promise<any> {
    BrowserLogger.log('UnitsService.createNewEndpointController', { data });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/site-controllers/configure/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updateEndpointController(controllerId, data): Promise<any> {
    BrowserLogger.log('UnitsService.updateEndpointController', { controllerId, data });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/site-controllers/controllers/' + controllerId + '/',
      RequestMethod.PUT,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public removeEndpointController(controllerId): Promise<any> {
    BrowserLogger.log('UnitsService.removeEndpointController', { controllerId });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/site-controllers/controllers/' + controllerId + '/',
      RequestMethod.DELETE,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public removeEndpointControllerFromDroplet(data): Promise<any> {
    BrowserLogger.log('UnitsService.removeEndpointControllerFromDroplet', { data });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/site-controllers/delete-message/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public resendControllerConfig(controllerId): Promise<any> {
    BrowserLogger.log('UnitsService.resendControllerConfig', { controllerId });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/site-controllers/controllers/' + controllerId + '/sync/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public addNewDeviceInController(controllerId, deviceId): Promise<any> {
    BrowserLogger.log('UnitsService.addNewDeviceInController', { controllerId, deviceId });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/site-controllers/controllers/' + controllerId + '/add-device/' + deviceId + '/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public removeDeviceInController(controllerId, deviceId): Promise<any> {
    BrowserLogger.log('UnitsService.removeDeviceInController', { controllerId, deviceId });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/site-controllers/controllers/' + controllerId + '/remove-device/' + deviceId + '/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getDeviceSettingControlValue(deviceSettingControlId): Promise<any> {
    BrowserLogger.log('UnitsService.getDeviceSettingControlValue', { deviceSettingControlId });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/iec61850/device-setting-values/' + deviceSettingControlId + '/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getDeviceTypes(): Promise<any> {
    BrowserLogger.log('UnitsService.getDeviceTypes');
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v1/device-types/',
      RequestMethod.GET,
      UseHeaderType.AUTHORIZED_SWDIN,
      {},
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public getUserIP(): Promise<any> {
    BrowserLogger.log('UnitsService.getUserIP');
    return this.api.handleRequest(null, 'https://api.ipify.org?format=json', RequestMethod.GET, UseHeaderType.DEFAULT);
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public saveDeviceTypeForEndpointToProvideMeasurement(data): Promise<any> {
    BrowserLogger.log('UnitsService.saveDeviceTypeForEndpointToProvideMeasurement', { data });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      '/api/v2/devices/',
      RequestMethod.POST,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  // todo - strongly typed return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public updateDeviceName(data): Promise<any> {
    BrowserLogger.log('UnitsService.updateDeviceName', { data });
    return this.api.handleRequest(
      AvailableAPI.SWITCHDIN,
      `/api/v2/devices/${data.id}/`,
      RequestMethod.PUT,
      UseHeaderType.AUTHORIZED_SWDIN,
      data,
    );
  }

  public upgradeEndpoint(endpointId: string) {
    return this._endpointsApi.upgradeEndpoint(endpointId);
  }
}
